import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

import { TVerifiedLabelSize } from './label.model';

@Component({
    selector: 'ui-verified-label',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <mat-icon [svgIcon]="'check-circle'" color="accent" class="ui-verified-label-icon">
        </mat-icon>
        <span class="ui-verified-label-text"> Granskad av Vinbörsen </span>
    `,
    styleUrls: ['./verified-label.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UiVerifiedLabelComponent {
    @Input() size: TVerifiedLabelSize = 'large';

    @HostBinding('class.ui-verified-label') baseClassName = true;

    @HostBinding('attr.size') get attrStyle() {
        return this.size || null;
    }
}
