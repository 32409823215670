import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

import { coerceBoolean } from '@vb/utilities/coerce';

import {
    IUiLabel,
    TLabelBorder,
    TLabelColor,
    TLabelMode,
    TLabelSize,
    UiLabel
} from './label.model';

@Component({
    selector: 'ui-label',
    template: `<span><ng-content></ng-content></span>`,
    styleUrls: ['./label.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiLabelComponent {
    @Input() color?: TLabelColor;
    @Input() colorCode?: string;
    @Input() size?: TLabelSize;
    @Input() mode?: TLabelMode;
    @Input() border?: TLabelBorder;
    @Input() inline?: boolean;

    @Input() set settings(config: IUiLabel) {
        Object.keys(config || {}).forEach((k) => {
            if (Object.hasOwnProperty.call(UiLabel.defaults, k)) {
                this[k] = config[k];
            }
        });
    }

    @HostBinding('class.ui-label') baseClass = true;

    @HostBinding('attr.color') get attrColor() {
        return this.color || null;
    }

    @HostBinding('style.backgroundColor') get styleColor() {
        return this.colorCode || null;
    }

    @HostBinding('attr.size') get attrSize() {
        return this.size || null;
    }

    @HostBinding('attr.mode') get attrStyle() {
        return this.mode || null;
    }

    @HostBinding('attr.border') get attrBorder() {
        return this.border || null;
    }

    @HostBinding('attr.inline') get attrInline() {
        return coerceBoolean(this.inline) || null;
    }
}
