import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    TemplateRef,
    ViewEncapsulation
} from '@angular/core';

import { IUiLabel } from './label.model';

@Component({
    selector: 'ui-label-list',
    template: `
        <ng-container *ngFor="let label of labels; let last = last">
            <ui-label [settings]="label" [color]="label?.color || undefined">
                {{ label.value }}
            </ui-label>
            <ng-container *ngIf="separator && !last" [ngTemplateOutlet]="separator"></ng-container>
        </ng-container>
        <ng-content
            select="ui-label, ui-label-group, [ui-label], [ui-label-group], ui-separator"
        ></ng-content>
    `,
    styleUrls: ['./label-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiLabelListComponent {
    @Input() labels: IUiLabel[] | null = [];
    @Input() separator?: TemplateRef<any> | null;

    @HostBinding('class.ui-label-list') baseClass = true;
}
