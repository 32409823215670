import { TColor } from '@vb/shared/interfaces';

export type TLabelColor = TColor;

export type TLabelSize = 'small' | 'medium' | 'large';
export type TLabelMode = 'solid' | 'background' | 'outlined' | 'none';
export type TLabelBorder = 'rounded' | 'circular' | 'none';
export type TVerifiedLabelSize = 'small' | 'large';

export interface IUiLabel {
    value?: any;
    color?: TLabelColor;
    size?: TLabelSize;
    mode?: TLabelMode;
    border?: TLabelBorder;
    inline?: boolean;
}

export class UiLabel {
    static get defaults() {
        return {
            color: undefined,
            size: 'medium',
            mode: 'none',
            border: 'none'
        } as IUiLabel;
    }

    value = undefined;
    color = UiLabel.defaults.color;
    size = UiLabel.defaults.size;
    mode = UiLabel.defaults.mode;
    border = UiLabel.defaults.border;

    constructor(config: IUiLabel = {}) {
        Object.keys(config).forEach((k) => {
            if (Object.hasOwnProperty.call(UiLabel.defaults, k)) {
                this[k] = config[k];
            }
        });
    }
}
