import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { UiLabelGroupComponent } from './label-group.component';
import { UiLabelListComponent } from './label-list.component';
import { UiLabelComponent } from './label.component';
import { UiVerifiedLabelComponent } from './verified-label.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [
        UiLabelComponent,
        UiLabelGroupComponent,
        UiLabelListComponent,
        UiVerifiedLabelComponent
    ],
    exports: [
        UiLabelComponent,
        UiLabelGroupComponent,
        UiLabelListComponent,
        UiVerifiedLabelComponent
    ]
})
export class UiLabelModule {}
