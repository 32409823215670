import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-label-group, [ui-label-group]',
    template: '<ng-content select="ui-label, [ui-label]"></ng-content>',
    styleUrls: ['./label-group.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'ui-label-group'
    }
})
export class UiLabelGroupComponent {}
